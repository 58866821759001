import Mock from "../mock";

const database = {
  information: {
    name: 'Chimaobi Johnson Wogu',
    aboutContent: "I am a Full-stack Software Developer and I can provide clean code and pixel perfect design.",
    age: 29,
    phone: '',
    nationality: 'Nigerian',
    language: 'English',
    email: '',
    address: 'Lagos, Nigeria',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://linkedin.com/in/wogu-chimaobi',
      dribbble: '',
      github: 'https://github.com/Chimaobi-Johnson'
    },
    brandImage: '/images/chimaobi-new.jpg',
    aboutImage: '/images/tablet.jpg',
    aboutImageLg: '/images/tablet.jpg',
    cvfile: '/files/Wogu_Chimaobi_Johnson_CV.pdf'
  },
  services: [
    {
      title: "Web Design",
      icon: 'brush-alt',
      details: "I build responive websites that are SEO friendly"
    },
    {
      title: "Web Development",
      icon: 'code',
      details: "I develop web applications using the latest technologies that are efficient and scalable"
    },
    {
      title: "Mobile Application",
      icon: 'mobile',
      details: "I can develop mobile applications that runs on both android and IOS platforms"
    }
  ],
  reviews: [
    // {
    //   id: 1,
    //   content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
    //   author: {
    //     name: 'Burdette Turner',
    //     designation: 'Web Developer, Abc Company'
    //   }
    // },
    // {
    //   id: 2,
    //   content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
    //   author: {
    //     name: 'Susan Yost',
    //     designation: 'Client'
    //   }
    // },
    // {
    //   id: 3,
    //   content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    //   author: {
    //     name: 'Irving Feeney',
    //     designation: 'Fiverr Client'
    //   }
    // }
  ],
  skills: [
    {
      title: "HTML5",
      value: 95
    },
    {
      title: "CSS3",
      value: 95
    },
    {
      title: "Javascript",
      value: 95
    },
    {
      title: "NodeJS",
      value: 85
    },
    {
      title: "ReactJS",
      value: 95
    },
    {
      title: "MongoDB",
      value: 95
    }
  ],
  portfolios: [
    {
      id: 0,
      title: "Resume AI",
      subtitle: " In Development | NextJS (TypeScript) | Tailwind CSS | Firebase",
      imageUrl: "/images/resumeai_dashboard.png",
      largeImageUrl: ["/images/resumeai_create_resume.png", "/images/resumeai_view_profile.png", "/images/resumeai_dashboard.png", "/images/resumeai_tailored_resume.png", "/images/resumeai_pricing.png"],
      url: 'https://resumeai-e568f.web.app/'
    },
    {
      id: 1,
      title: "Delight stores",
      subtitle: " In Development | NextJS | CSS Grid | Netlify",
      imageUrl: "/images/delight-new2.png",
      largeImageUrl: ["/images/dlight-store2.png", "/images/delight-new1.png", "/images/delight-stores-full.png", "/images/delight-new2.png", "/images/dlight-store1.png", "/images/delight-new3.png"],
      url: 'https://delighthomewarestores.com'
    },
    {
      id: 2,
      title: "Delight stores dashboard",
      subtitle: " In Development | ReactJS | Minimal UI Kit | Heroku",
      imageUrl: "/images/dashboard-home.png",
      largeImageUrl: ["/images/dashboard-home.png", "/images/create-product.png", "/images/products-list.png", "/images/delight_dashboard_img_lq.png", "/images/dashboard-2.png", "/images/dashboard-3.png"],
      url: 'https://delight-stores-dashboard.herokuapp.com/'
    },
    {
      id: 3,
      title: "BloomX",
      subtitle: " In Development | NextJS (TypeScript) | tailwind CSS | Socket IO | MongoDB | Heroku ",
      imageUrl: "/images/bloomx.png",
      largeImageUrl: ["https://share.vidyard.com/watch/xVZkbL6KyEsF7Km3M9GACf?"],
      url: 'https://share.vidyard.com/watch/xVZkbL6KyEsF7Km3M9GACf?'
    },
    {
      id: 4,
      title: "Tasks2Do!",
      subtitle: "ReactJS | Firebase | Netlify",
      imageUrl: "/images/tasks2do-landing.png",
      largeImageUrl: ["/images/tasks2do-landing2.png"],
      url: 'https://62946b17302e986b19b2680c--melodious-shortbread-adb286.netlify.app/'
    },
    {
      id: 5,
      title: "Whatsapp clone",
      subtitle: " In Development | ReactJS | Netlify",
      imageUrl: "/images/whatsapp-clone.png",
      largeImageUrl: ["/images/whatsapp-clone.png"],
      url: 'https://serene-bardeen-cf51b5.netlify.app/'
    },
  
    {
      id: 6,
      title: "SurveyBuddy",
      subtitle: "ReactJS | ExpressJS | NodeJS | MongoDB",
      imageUrl: "/images/surveybuddy-landing.png",
      largeImageUrl: ["/images/surveybuddy-page.png", "/images/surveybuddy-page2.png"],
      url: 'https://surveybuddy.herokuapp.com/'
    },
    {
      id: 7,
      title: "Marvtech",
      subtitle: "ReactJS | Heroku",
      imageUrl: "/images/marvtech-landing.png",
      largeImageUrl: [
        "/images/marvtech-half.png",
        "/images/marvtech-half2.png"
      ],
      url: 'https://marvtech.herokuapp.com/'
    },
    {
      id: 8,
      title: "Druup",
      subtitle: "ReactJS | ExpressJS | NodeJS | MongoDB",
      imageUrl: "/images/druup-landing.png",
      largeImageUrl: ["/images/druup-page1.png", "/images/druup-page2.png", "/images/druup-page3.png", "/images/druup-page4.png", "/images/druup-page5.png"],
      url: 'http://druup.herokuapp.com/'
    },

    {
      id: 9,
      title: "GearBuddy",
      subtitle: "ReactJS | ExpressJS | NodeJS | MongoDB",
      imageUrl: "/images/gearbuddy-landing.png",
      largeImageUrl: ["/images/gearbuddy-landing2.png", "/images/gearbuddy-half.png", "/images/gearbuddy-half2.png"],
      url: 'https://gear-buddy.herokuapp.com/'
    },
    {
      id: 10,
      title: "Marvis Store",
      subtitle: "HTML | CSS | SASS | Javascript | NodeJS | MongoDB",
      imageUrl: "/images/marvis-landing.png",
      largeImageUrl: [ "/images/marvis-dashboard.png", "/images/marvis-landing2.png", "/images/marvis-full.png", "/videos/marvis-store-vid.mp4",]
    },


    {
      id: 11,
      title: "TeesKitchen",
      subtitle: "In Development | ReactJS | NodeJS | MongoDB",
      imageUrl: "/images/teeskitchen-landing.png",
      largeImageUrl: ["/images/teeskitchen-half.png"],
      url: 'http://teeskitchen.herokuapp.com/'
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "May 2024 - Present",
        position: "Software Developer",
        company: "Onstriit",
        details: "Continuous improvement on the usability and reliability of the Resume AI web application. Building web components and pages from the Resume AI UI design which includes the website and dashboard. Developing, deploying, and maintaining backend services and APIs using firebase. Developing a web Chrome extension that will work with application requirements. Development of more features in the app as requested. Providing solutions to support/bug-fixing requests "
      },
      {
        id: 2,
        year: "September 2023 – July 2024",
        position: "Full-stack Developer",
        company: "Bloomx Nigeria",
        details: "Monitored and contributed to the development of an E-checkout platform using Vercel, React.js, TypeScript, Next.js, MongoDB, Cloudinary, and Express.js. Providing technical support to the BloomX team and advising on how to best implement new features to their application. Developed UI components with Tailwind CSS and implemented Google Authentication with OAuth20. Integrated online payment systems like card checkout with Monnify and a virtual wallet system using getAnchor payment platform."
      },
      {
        id: 3,
        year: "September 2020 – January 2021",
        position: "Software Developer",
        company: "Loctech Nigeria Limited",
        details: "Worked closely with a software team to build and maintain an Enterprise Resource Planning Application for a haulage company. I was able to contribute to the team by developing the Client and Marine modules of the application using React.js with material UI framework. I was also tasked with working on form validations and search queries on the backend for the Finance module using MongoDB aggregation and other querying methods. Attended product meetings with clients and helped to identify more efficient work flows for the company departments which will suite the application Rebuilt the company's web application with a colleague using React.js."
      },
      {
        id: 4,
        year: "May 2020 – September 2020",
        position: "Software Engineer (Volunteer)",
        company: "FightPandemics(DoinGud)",
        details: "Built web components and some pages for the web application using React.js and ant design framework. Some of these components include the navigation bar, landing page, nearest hospital page, add and edit organization pages, organization home page and mobile navigation tabs. Made use of styled components Worked closely with the engineering, product, and design teams to introduce new features to the application and complete existing features. Fixed bugs and reviewed code on GitHub for developers in my team. Volunteered as the engineering lead for the organization profile feature."
      },
      {
        id: 5,
        year: "January 2018 – June 2018",
        position: "Web Developer Intern",
        company: "Santos Creation Education Foundation",
        details: "Worked on the frontend of the New Nigerian Education Awards website using HTML, CSS, Bootstrap and Javascript. Studied the development process for a web-based voting application Made written research on several web-related topics which includes web hosts, application security, and the right frameworks to use for developing web applications."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2021 - 2022",
        graduation: "Master of Science",
        university: "Birmingham City University",
        details: "International Business and Management"
      },
      {
        id: 2,
        year: "2012 - 2016",
        graduation: "Bachelor of Science",
        university: "Michael Okpara University",
        details: "Entrepreneurial Studies"
      },
    ]
  },
  blogs: [
    {
      id: 8,
      title: 'Image upload in React-native using cloudinary cloud service',
      featuredImage: '/images/blog-image-8.jpg',
      filesource: '../../blog/time-to-use-latest-technology-for-creating-a-website.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
  ],
  contactInfo: {
    phoneNumbers: [ '+234 7077708306', '+44 7759991282' ],
    emailAddress: ['chimaobi.dev@gmail.com', 'woguchimaobi@gmail.com'],
    address: "Lagos, Nigeria"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});